import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { Col } from "@/components/Base";
import NavBar from "./NavBar";

const mapStateToProps = (state) => ({
    auth: state.auth,
});

@connect(mapStateToProps)
export default class UserLayout extends React.Component {
    render () {
        const { auth, children, ...props } = this.props;
        const hasPermission = auth?.loggedIn === true;

        if (hasPermission) {
            if (children) {
                // add props to children
                return (
                    <Col padding="48px 0 0 0" height="100%">
                        <NavBar />
                        {React.cloneElement(children, props)}
                    </Col>
                );
            } else {
                // render outlet
                return (
                    <Col padding="48px 0 0 0" height="100%">
                        <NavBar />
                        <Outlet {...props} />
                    </Col>
                );
            }
        } else {
            return <Navigate to="/auth/login" />;
        }
    };
}