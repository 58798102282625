import React from "react";
import { Outlet } from "react-router-dom";

const AuthLayout = ({ children, ...props }) => {
    if (children) {
        // add props to children
        return React.cloneElement(children, props);
    } else {
        // render outlet
        return <Outlet {...props} />;
    }
};

export default AuthLayout;