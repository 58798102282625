import React, { useState } from "react";
import { connect } from "react-redux";

import withRouter from "@/components/withRouter";
import { Row, Col, Text } from "@/components/Base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { menu } from "./menu";

import { PERMISSION_TEST_FUNCTIONS } from "@/services/permission";

const mapStateToProps = (state) => ({
    auth: state.auth,
    user: state.user,
});

const UserNavBar = ({ router, auth, user }) => {
    const [menuVisible, setMenuVisible] = useState(false);

    return (
        <Col position="fixed" top={0} zIndex={100} >
            <Row height={48} align="center" justify="space-between" padding="0 12px" background="var(--main-bg-color)">
                <Row justify="center" align="center" width="auto">
                    <img src="/sxzq.png" alt="logo" height="36" />
                </Row>
                <FontAwesomeIcon icon={solid("bars")} style={{ fontSize: 20 }} onClick={() => setMenuVisible(!menuVisible)} />
            </Row>
            {/* 导航主体 */}
            <Col maxHeight={menuVisible ? menu.length * 43 : 0} overflow="hidden" background="var(--sec-bg-color)" transition>
                {
                    menu.map((item, index) => (!item.perm || PERMISSION_TEST_FUNCTIONS[item.perm.level](auth, user, item?.perm?.exact ?? false)) && (
                        <Row
                            key={index}
                            gap="12px"
                            align="center"
                            padding="12px 24px"
                            cursor="pointer"
                            background={router.location.pathname === item.path ? "var(--accent-bg-color)" : "transparent"}
                            color={router.location.pathname === item.path ? "var(--text-reverse-color)" : "var(--text-color)"}
                            onClick={() => {
                                if (typeof item?.onClick === "function") {
                                    item.onClick(router);
                                } else {
                                    router.navigate(item.path);
                                }
                                setMenuVisible(false);
                            }}
                        >
                            {item.icon}
                            <Text size="14px" weight="bold" color="inherit">{item.name}</Text>
                        </Row>
                    ))
                }
            </Col>
            {/* 遮罩 */}
            <Row
                position="absolute"
                top="0"
                left="0"
                height="100vh"
                background="black"
                opacity={menuVisible ? 0.5 : 0}
                visible={menuVisible}
                zIndex={-1}
                transition
                onClick={() => setMenuVisible(false)}
            />
        </Col >
    );
};

export default withRouter(connect(mapStateToProps)(UserNavBar));