import { useEffect } from "react";
import { connect } from "react-redux";

import withRouter from "@/components/withRouter";

import { clearUserInfo } from "@/store/reducers/user";
import { logout } from "@/store/reducers/auth";

import { auth } from "@/requests";

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearUserInfo: () => dispatch(clearUserInfo()),
});

const LogoutApp = function (props) {
    const { logout, clearUserInfo, router } = props;

    useEffect(() => {
        auth.logout()
            .then(() => {
                logout();
                clearUserInfo();
                router.navigate("/");
            });
    }, []);

    return null;
};

export default withRouter(connect(null, mapDispatchToProps)(LogoutApp));