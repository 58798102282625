import React from "react";
import styled from "styled-components";
import FeaturePermission from "./Hoc/FeaturePermission";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: ${({ position }) => position ? position : "relative"};
  width: ${({ width }) => width !== undefined ? `${typeof width === "number" ? `${width}px` : width}` : "100%"};
  height: ${({ height }) => height !== undefined ? `${typeof height === "number" ? `${height}px` : height}` : "auto"};
  max-width: ${({ maxWidth }) => maxWidth !== undefined ? `${typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth}` : undefined};
  max-height: ${({ maxHeight }) => maxHeight !== undefined ? `${typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight}` : undefined};
  min-width: ${({ minWidth }) => minWidth !== undefined ? `${typeof minWidth === "number" ? `${minWidth}px` : minWidth}` : undefined};
  min-height: ${({ minHeight }) => minHeight !== undefined ? `${typeof minHeight === "number" ? `${minHeight}px` : minHeight}` : undefined};
  top: ${({ top }) => top !== undefined ? `${typeof top === "number" ? `${top}px` : top}` : undefined};
  left: ${({ left }) => left !== undefined ? `${typeof left === "number" ? `${left}px` : left}` : undefined};
  right: ${({ right }) => right !== undefined ? `${typeof right === "number" ? `${right}px` : right}` : undefined};
  bottom: ${({ bottom }) => bottom !== undefined ? `${typeof bottom === "number" ? `${bottom}px` : bottom}` : undefined};
  color: ${({ color }) => color ? color : "var(--text-color)"};
  background: ${({ background }) => background ? background : "transparent"};
  border: ${({ border }) => border ? border : "none"};
  border-radius: ${({ radius }) => radius !== undefined ? `${typeof radius === "number" ? `${radius}px` : radius}` : undefined};
  /* gap: ${({ gap }) => gap !== undefined ? `${typeof gap === "number" ? `${gap}px` : gap}` : "0"}; */
  ${({ gap }) => {
        if (gap !== undefined) {
            return `
              & > * {
                  margin-left: calc(${typeof gap === "number" ? `${gap}px` : gap} / 2);
                  margin-right: calc(${typeof gap === "number" ? `${gap}px` : gap} / 2);
              }
              & > *:first-child {
                  margin-left: 0;
              }
              & > *:last-child {
                  margin-right: 0;
              }
        `;
        }
    }}
  align-items: ${({ align }) => align ? align : "stretch"};
  justify-content: ${({ justify }) => justify ? justify : "flex-start"};
  margin: ${({ margin }) => {
        if (typeof margin === "number") return `${typeof margin === "number" ? `${margin}px` : margin}`;
        else if (typeof margin === "string") return margin;
        else if (Array.isArray(margin)) return `${margin.join("px ")}px`;
        else return undefined;
    }};
  padding: ${({ padding }) => {
        if (typeof padding === "number") return `${typeof padding === "number" ? `${padding}px` : padding}`;
        else if (typeof padding === "string") return padding;
        else if (Array.isArray(padding)) return `${padding.join("px ")}px`;
        else if (padding === true) return "12px";
        else return undefined;
    }};
  flex-wrap: ${({ wrap }) => wrap ? "wrap" : "nowrap"};
  flex-grow: ${({ grow }) => grow ? "1" : "0"};
  flex-shrink: ${({ noShrink }) => noShrink ? "0" : "1"};
  overflow: ${({ overflow }) => overflow ? overflow : "visible"};
  z-index: ${({ zIndex }) => zIndex !== undefined ? zIndex : undefined};
  transition: ${({ transition }) => {
        if (typeof transition === "boolean") return "all 0.3s ease-in-out";
        else if (typeof transition === "string") return transition;
        else return undefined;
    }};
  opacity: ${({ opacity }) => opacity !== undefined ? opacity : "1"};
  visibility: ${({ visible }) => visible !== undefined ? (visible ? "visible" : "hidden") : "visible"};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  position: ${({ position }) => position ? position : "relative"};
  width: ${({ width }) => width !== undefined ? `${typeof width === "number" ? `${width}px` : width}` : "100%"};
  height: ${({ height }) => height !== undefined ? `${typeof height === "number" ? `${height}px` : height}` : "auto"};
  max-width: ${({ maxWidth }) => maxWidth !== undefined ? `${typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth}` : undefined};
  max-height: ${({ maxHeight }) => maxHeight !== undefined ? `${typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight}` : undefined};
  min-width: ${({ minWidth }) => minWidth !== undefined ? `${typeof minWidth === "number" ? `${minWidth}px` : minWidth}` : undefined};
  min-height: ${({ minHeight }) => minHeight !== undefined ? `${typeof minHeight === "number" ? `${minHeight}px` : minHeight}` : undefined};
  top: ${({ top }) => top !== undefined ? `${typeof top === "number" ? `${top}px` : top}` : undefined};
  left: ${({ left }) => left !== undefined ? `${typeof left === "number" ? `${left}px` : left}` : undefined};
  right: ${({ right }) => right !== undefined ? `${typeof right === "number" ? `${right}px` : right}` : undefined};
  bottom: ${({ bottom }) => bottom !== undefined ? `${typeof bottom === "number" ? `${bottom}px` : bottom}` : undefined};
  color: ${({ color }) => color ? color : "var(--text-color)"};
  background: ${({ background }) => background ? background : "transparent"};
  border: ${({ border }) => border ? border : "none"};
  border-radius: ${({ radius }) => radius !== undefined ? `${typeof radius === "number" ? `${radius}px` : radius}` : undefined};
  /* gap: ${({ gap }) => gap !== undefined ? `${typeof gap === "number" ? `${gap}px` : gap}` : "0"}; */
  ${({ gap }) => {
        if (gap !== undefined) {
            return `
              & > * {
                  margin-top: calc(${typeof gap === "number" ? `${gap}px` : gap} / 2);
                  margin-bottom: calc(${typeof gap === "number" ? `${gap}px` : gap} / 2);
              }
              & > *:first-child {
                  margin-top: 0;
              }
              & > *:last-child {
                  margin-bottom: 0;
              }
        `;
        }
    }}
  align-items: ${({ align }) => align ? align : "stretch"};
  justify-content: ${({ justify }) => justify ? justify : "flex-start"};
  margin: ${({ margin }) => {
        if (typeof margin === "number") return `${typeof margin === "number" ? `${margin}px` : margin}`;
        else if (typeof margin === "string") return margin;
        else if (Array.isArray(margin)) return `${margin.join("px ")}px`;
        else return undefined;
    }};
  padding: ${({ padding }) => {
        if (typeof padding === "number") return `${typeof padding === "number" ? `${padding}px` : padding}`;
        else if (typeof padding === "string") return padding;
        else if (Array.isArray(padding)) return `${padding.join("px ")}px`;
        else if (padding === true) return "12px";
        else return undefined;
    }};
  flex-wrap: ${({ wrap }) => wrap ? "wrap" : "nowrap"};
  flex-grow: ${({ grow }) => grow ? "1" : "0"};
  flex-shrink: ${({ noShrink }) => noShrink ? "0" : "1"};
  overflow: ${({ overflow }) => overflow ? overflow : "visible"};
  z-index: ${({ zIndex }) => zIndex !== undefined ? zIndex : undefined};
  transition: ${({ transition }) => {
        if (typeof transition === "boolean") return "all 0.3s ease-in-out";
        else if (typeof transition === "string") return transition;
        else return undefined;
    }};
  opacity: ${({ opacity }) => opacity !== undefined ? opacity : "1"};
  visibility: ${({ visible }) => visible !== undefined ? (visible ? "visible" : "hidden") : "visible"};
`;

export const Text = styled.span`
  position: ${({ position }) => position ? position : "relative"};
  width: ${({ width }) => width !== undefined ? `${typeof width === "number" ? `${width}px` : width}` : "auto"};
  height: ${({ height }) => height !== undefined ? `${typeof height === "number" ? `${height}px` : height}` : "auto"};
  max-width: ${({ maxWidth }) => maxWidth !== undefined ? `${typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth}` : undefined};
  max-height: ${({ maxHeight }) => maxHeight !== undefined ? `${typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight}` : undefined};
  min-width: ${({ minWidth }) => minWidth !== undefined ? `${typeof minWidth === "number" ? `${minWidth}px` : minWidth}` : undefined};
  min-height: ${({ minHeight }) => minHeight !== undefined ? `${typeof minHeight === "number" ? `${minHeight}px` : minHeight}` : undefined};
  top: ${({ top }) => top !== undefined ? `${typeof top === "number" ? `${top}px` : top}` : undefined};
  left: ${({ left }) => left !== undefined ? `${typeof left === "number" ? `${left}px` : left}` : undefined};
  right: ${({ right }) => right !== undefined ? `${typeof right === "number" ? `${right}px` : right}` : undefined};
  bottom: ${({ bottom }) => bottom !== undefined ? `${typeof bottom === "number" ? `${bottom}px` : bottom}` : undefined};
  color: ${({ color }) => color ? color : "var(--text-color)"};
  background: ${({ background }) => background ? background : "transparent"};
  border: ${({ border }) => border ? border : "none"};
  border-radius: ${({ radius }) => radius !== undefined ? `${typeof radius === "number" ? `${radius}px` : radius}` : undefined};
  font-weight: ${({ weight }) => weight ? weight : "inherit"};
  font-size: ${({ size }) => size ? `${typeof size === "number" ? `${size}px` : size}` : "inherit"};
  text-align: ${({ align }) => align ? align : "inherit"};
  margin: ${({ margin }) => {
        if (typeof margin === "number") return `${typeof margin === "number" ? `${margin}px` : margin}`;
        else if (typeof margin === "string") return margin;
        else if (Array.isArray(margin)) return `${margin.join("px ")}px`;
        else return undefined;
    }};
  padding: ${({ padding }) => {
        if (typeof padding === "number") return `${typeof padding === "number" ? `${padding}px` : padding}`;
        else if (typeof padding === "string") return padding;
        else if (Array.isArray(padding)) return `${padding.join("px ")}px`;
        else return undefined;
    }};
  flex-grow: ${({ grow }) => grow ? "1" : "0"};
  flex-shrink: ${({ noShrink }) => noShrink ? "0" : "1"};
  cursor: ${({ pointer }) => pointer ? pointer : "inherit"};
  z-index: ${({ zIndex }) => zIndex !== undefined ? zIndex : undefined};
  transition: ${({ transition }) => {
        if (typeof transition === "boolean") return "all 0.3s ease-in-out";
        else if (typeof transition === "string") return transition;
        else return undefined;
    }};
  opacity: ${({ opacity }) => opacity !== undefined ? opacity : "1"};
  visibility: ${({ visible }) => visible !== undefined ? (visible ? "visible" : "hidden") : "visible"};
  white-space: ${({ noWrap }) => noWrap ? "nowrap" : undefined};
  overflow: ${({ overflow, ellipsis }) => overflow ? overflow : ellipsis ? "hidden" : "visible"};
  text-overflow: ${({ ellipsis }) => ellipsis ? "ellipsis" : undefined};
`;

export const Page = ({ permission, children, ...props }) => {
    // Add props to children
    return (
        <FeaturePermission permission={permission} noPermissionNode>
            <Col width="100%" padding="12px" align="center" justify="flex-start" gap="12px" {...props}>
                {children}
            </Col>
        </FeaturePermission>
    );
};