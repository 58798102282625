export default class FileRequests {
    constructor (axios) {
        this.$axios = axios;
    }

    async upload (file, onProgress) {
        // 直接上传到 OSS
        // 先获取签名
        const sign = (
            await this.$axios({
                method: "GET",
                url: "/file/policy",
            })
        ).data;
        const formData = new FormData();
        formData.set("key", sign.dir + file.name);
        formData.set("policy", sign.policy);
        formData.set("OSSAccessKeyId", sign.accessid);
        formData.set("success_action_status", "200");
        formData.set("signature", sign.signature);
        formData.set("file", file);
        const res = await this.$axios({
            method: "POST",
            url: sign.host,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 0,
            onUploadProgress: onProgress,
        });
        if (res.status === 200) {
            return sign.host + "/" + sign.dir + file.name;
        } else {
            throw new Error("上传失败");
        }
    };

    async uploadAudioAndTranscode (file, onProgress) {
        // 上传音频文件并转码
        // 先获取签名
        const sign = (
            await this.$axios({
                method: "GET",
                url: "/file/policy",
                params: {
                    path: "original",
                },
            })
        ).data;
        const formData = new FormData();
        formData.set("key", sign.dir + file.name);
        formData.set("policy", sign.policy);
        formData.set("OSSAccessKeyId", sign.accessid);
        formData.set("success_action_status", "200");
        formData.set("signature", sign.signature);
        formData.set("file", file);
        const res = await this.$axios({
            method: "POST",
            url: sign.host,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 0,
            onUploadProgress: (e) => {
                // max 50%
                onProgress({
                    loaded: e.loaded / 2,
                    total: e.total,
                });
            },
        });
        if (res.status === 200) {
            const transcodedUrl = sign.host + "/" + sign.dir.replace(/^original/, "transcode") + file.name.replace(/\.(\w+)$/, ".mp3");
            // 检查转码是否完成
            let ok = false;
            let loaded = 50;
            while (!ok) {
                try {
                    await this.$axios({
                        method: "HEAD",
                        url: transcodedUrl,
                    });
                    ok = true;
                } catch (e) {
                    loaded += Math.random();
                    onProgress({
                        loaded: loaded > 99 ? 99 : loaded,
                        total: 100,
                    });
                    await new Promise(resolve => setTimeout(resolve, 200));
                }
            }
            onProgress({
                loaded: 100,
                total: 100,
            });
            await new Promise(resolve => setTimeout(resolve, 200));
            return transcodedUrl;
        } else {
            throw new Error("上传失败");
        }
    }
}