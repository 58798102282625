import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        id: null,
        common_name: "",
        last_name: "",
        first_name: "",
        phone: "",
        email: "",
        is_active: false,
        is_qbnewb: false,
        is_superuser: false,
        personal_collection_id: -1,
        sxzq_collection_id: -1,
        date_joined: null,
        last_login: null,
        updated_at: null,
        configList: [],
        perms: [],
    },
    reducers: {
        setUserInfo: (state, action) => {
            const keys = Object.keys(state);
            for (const key of keys) {
                if (action.payload.hasOwnProperty(key)) {
                    state[key] = action.payload[key];
                }
            }
        },
        clearUserInfo: (state) => {
            state.id = null;
            state.common_name = "";
            state.last_name = "";
            state.first_name = "";
            state.phone = "";
            state.email = "";
            state.is_active = false;
            state.is_qbnewb = false;
            state.is_superuser = false;
            state.personal_collection_id = -1;
            state.sxzq_collection_id = -1;
            state.date_joined = null;
            state.last_login = null;
            state.updated_at = null;
            state.configList = [];
            state.perms = [];
        },
    },
});

export const { setUserInfo, clearUserInfo } = userSlice.actions;

export default userSlice.reducer;