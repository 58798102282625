import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

export default function TitleWrapper ({ title, children, ...props }) {
    useEffect(() => {
        document.title = `${title} - 山西证券`;
    }, [title]);

    if (children) {
        // add props to children
        return React.cloneElement(children, props);
    } else {
        // render outlet
        return <Outlet {...props} />;
    }
};