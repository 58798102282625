export default class ViewpointRequests {
    constructor (axios) {
        this.$axios = axios;
    }

    async getCategories () {
        const response = await this.$axios({
            method: "GET",
            url: "/report/collection/subMenuAdmin",
        });
        return response.data?.data;
    };

    async getMyList ({
        variety,
        classification,
        page,
        pageSize,
        keyword,
        startDate,
        endDate,
        interval,
        researcher,
        sort,
    }) {
        if ([page, pageSize].includes(undefined)) {
            throw new Error("page and pageSize are required");
        }

        const response = await this.$axios({
            method: "POST",
            url: "/viewpoint/user/list",
            data: {
                pageNo: page,
                pageSize,
                orderBy: sort,
                variety,
                type: classification,
                keyword,
                startDate,
                endDate,
                interval,
                creatorId: researcher,
            },
        });

        return response.data?.data;
    };

    async getReportList ({ collectionId }) {
        if (collectionId === undefined) {
            throw new Error("collectionId is required");
        }

        const response = await this.$axios({
            method: "GET",
            url: "/report/query",
            params: {
                pageNo: 1,
                pageSize: 1000,
                collectionId,
            },
        });

        return response.data.data;
    };

    async detail (id) {
        if (id === undefined) {
            throw new Error("id is required");
        }

        const response = await this.$axios({
            method: "GET",
            url: `/viewpoint/${id}`,
        });

        return response.data?.data;
    };

    async create ({
        interval,
        date,
        variety,
        classification,
        reportId, // 关联的报告id
        reportPath,
        isCopy, // 0: create, 1: copy
        title,
        questionId,
        role,
    }) {
        const response = await this.$axios({
            method: "POST",
            url: "/viewpoint/create",
            data: {
                interval,
                date,
                variety,
                type: classification,
                reportId,
                reportPath,
                isCopy,
                title,
                questionId,
                role,
            },
        });

        return response.data?.data;
    };

    async update ({
        id,
        interval,
        date,
        variety,
        classification,
        reportId,
        reportPath,
        isCopy = 0, // 0: false, 1: true
        title,
        status, // 0 unpublished, 1 published, 2 deleted
        content,
        img,
        mp3,
        questionId,
        role,
        reportCardImg,
    }) {
        if (id === undefined) {
            throw new Error("id is required");
        }

        const response = await this.$axios({
            method: "PUT",
            url: "/viewpoint/update",
            data: {
                id,
                interval,
                date,
                variety,
                type: classification,
                reportId,
                reportPath,
                isCopy,
                title,
                status,
                content,
                img,
                mp3,
                questionId,
                role,
                reportCardImg,
            },
        });

        return response.data?.data;
    };

    async publish (id, reportId) {
        if (id === undefined) {
            throw new Error("id is required");
        }

        const { data } = await this.$axios({
            method: "PUT",
            url: "/viewpoint/update",
            data: {
                id,
                status: 1,
                isCopy: 0,
                reportId,
            },
        });

        return data;
    }

    async unpublish (id, reportId) {
        if (id === undefined) {
            throw new Error("id is required");
        }

        const { data } = await this.$axios({
            method: "PUT",
            url: "/viewpoint/update",
            data: {
                id,
                status: 0,
                isCopy: 0,
                reportId,
            },
        });

        return data;
    }

    async delete (id) {
        if (id === undefined) {
            throw new Error("id is required");
        }

        const { data } = await this.$axios({
            method: "DELETE",
            url: `/viewpoint/delete/${id}`,
        });

        return data;
    }

    /**
     * 获取品种的树状数据
     *  报表
     *  | "AUTH_TYPE_DASHBOARD"
     * 数据库
     *  | "AUTH_TYPE_DATADASHBOARD"
     * 观点库
     *  | "AUTH_TYPE_VIEWPOINT"
     * 图库
     *  | "AUTH_TYPE_GALLERY";
     * @param {{
     *         type:
     *             | "AUTH_TYPE_DASHBOARD"
     *                 | "AUTH_TYPE_DATADASHBOARD"
     *                 | "AUTH_TYPE_VIEWPOINT"
     *                 | "AUTH_TYPE_GALLERY";
     *     }} params
     */
    async getCollectionPermissionTreeForSave (params) {
        return (
            await this.$axios(
                {
                    url: "/collection/collectionPermissionTreeForSave",
                    method: "GET",
                    params,
                }
            )
        )?.data;
    };

    /**
     * 获取角色下拉
     * @param params
     * @returns {Promise<*>}
     */
    async getReportTypeList (
        params,
    ) {
        const response = await this.$axios(
            {
                url: "/reportDashboardGroupRoleAuth/getTypeList",
                params,
                method: "GET",
            }
        );

        return response.data;
    };
}