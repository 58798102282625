import React from "react";
import NoPermission from "@/components/Page/NoPermission";
import useFeaturePermission from "@/hook/useFeaturePermission";

interface Props {
  operator?: "and" | "or";
  permission?: string | Array<string>;
  noPermissionNode?: React.ReactNode | boolean;
  children: React.ReactNode;
}

export default function FeaturePermission({
  operator = "and",
  permission,
  noPermissionNode,
  children,
}: Props) {
  const hasFeaturePermission = useFeaturePermission(permission, operator);

  if (hasFeaturePermission) {
    return <>{children}</>;
  } else {
    if (noPermissionNode === true) {
      return <NoPermission />;
    } else {
      if (noPermissionNode) {
        return <>{noPermissionNode}</>;
      } else {
        return null;
      }
    }
  }
};