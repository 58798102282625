import React from "react";
import { connect } from "react-redux";
import { Input, Button, Checkbox, message } from "antd";

import withRouter from "@/components/withRouter";
import { Page, Row, Col, Text } from "@/components/Base";
import { login } from "@/store/reducers/auth";
import { setUserInfo } from "@/store/reducers/user";
import { auth, user } from "@/requests/index.js";

const mapDispatchToProps = (dispatch) => ({
    login: (token) => dispatch(login(token)),
    setUserInfo: (userInfo) => dispatch(setUserInfo(userInfo)),
});

@withRouter
@connect(null, mapDispatchToProps)
export default class Login extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            remember: false,
        };
    }
    usernameRef = React.createRef();
    passwordRef = React.createRef();

    handleLogin = async () => {
        const { login, setUserInfo, router } = this.props;
        const { remember } = this.state;
        const { usernameRef, passwordRef } = this;
        const username = usernameRef.current.input.value;
        const password = passwordRef.current.input.value;
        try {
            const { id: token } = await auth.loginByEmail({ username, password, remember });
            login(token);
            const userinfo = await user.getUserInfo();
            setUserInfo(userinfo);
            message.success("登录成功");
            router.navigate("/");
        } catch (e) {
            console.log(e);
            message.error("登录失败");
        }
    };

    toggleRemember = () => {
        this.setState({
            remember: !this.state.remember,
        });
    };

    render () {
        const { toggleRemember, handleLogin } = this;
        const { remember } = this.state;

        return (
            <Page>
                <Row justify="center" align="center" padding="32px 0">
                    <img src="/sxzq.png" alt="logo" height="70" />
                </Row>
                <Col gap={6}>
                    <Text size={14} weight="bold">用户名</Text>
                    <Input ref={this.usernameRef} size="large" />
                </Col>
                <Col gap={6}>
                    <Text size={14} weight="bold">密码</Text>
                    <Input ref={this.passwordRef} size="large" type="password" />
                </Col>
                <Row align="center" justify="space-between" onClick={toggleRemember}>
                    <Text size={14} weight="bold">记住我</Text>
                    <Checkbox checked={remember} />
                </Row>
                <Row>
                    <Button type="primary" style={{ width: "100%" }} size="large" onClick={handleLogin}>登录</Button>
                </Row>
            </Page>
        );
    }
}