import { useSelector } from "react-redux";

export default function useFeaturePermission(
  feature: string | string[] | undefined,
  operator: "and" | "or" = "and",
): boolean {
  const permissions: string[] =
    useSelector((state: any) => state?.user?.perms) ?? [];

  if (!feature) {
    return true;
  }

  if (Array.isArray(feature)) {
    if (operator === "and") {
      return feature.every((f) => permissions?.includes?.(f));
    } else if (operator === "or") {
      return feature.some((f) => permissions?.includes?.(f));
    }
  } else {
    return permissions?.includes?.(feature) ?? false;
  }

  return false;
}
