import axios from "axios";

import AuthRequests from "./services/auth";
import UserRequests from "./services/user";
import ViewpointRequests from "./services/viewpoint";
import FileRequests from "./services/file";

const axiosInstance = axios.create({
    baseURL: "/api",
    timeout: 10 * 1000,
    headers: {
        "Content-Type": "application/json",
    },
});

const requests = {
    auth: new AuthRequests(axiosInstance),
    user: new UserRequests(axiosInstance),
    viewpoint: new ViewpointRequests(axiosInstance),
    file: new FileRequests(axiosInstance),

};

export const { auth, user, viewpoint, file } = requests;

export default requests;