import React from "react";
import { Page } from "@/components/Base";

const NoPermission = () => (
    <Page>
      <div className="text-centered">
        <h1 className="text-brand">无权限</h1>
        <p className="text-medium">您没有权限操作这个页面</p>
      </div>
    </Page>
);

export default NoPermission;