export default class AuthRequests {
    constructor (axios) {
        this.$axios = axios;
    }

    async loginByEmail ({ username, password, remember }) {
        const response = await this.$axios({
            method: "post",
            url: "/session",
            data: {
                loginType: 1,
                username,
                password,
                remember,
            },
        });
        return response.data;
    };

    async getProperties () {
        const response = await this.$axios({
            method: "get",
            url: "/session/properties",
        });
        return response.data;
    };

    async logout () {
        const response = await this.$axios({
            method: "delete",
            url: "/session",
        });
        return response.data;
    };
}