import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export const menu = [
    {
        name: "观点库",
        icon: <FontAwesomeIcon icon={solid("lightbulb")} fixedWidth style={{ fontSize: 16 }} />,
        path: "/viewpoints",
        perm: {
            exact: false,
            level: "user",
        },
    },
    {
        name: "退出登录",
        icon: <FontAwesomeIcon icon={solid("door-open")} fixedWidth style={{ fontSize: 16 }} />,
        onClick: (router) => {
            Modal.confirm({
                title: "确认退出登录吗？",
                onOk: () => {
                    router.navigate("/auth/logout");
                },
            });
        },
        perm: {
            exact: false,
            level: "user",
        },
    },
];