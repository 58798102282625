export default class UserRequests {
    constructor (axios) {
        this.$axios = axios;
    }

    async getUserInfo () {
        const response = await this.$axios({
            method: "get",
            url: "/user/current",
        });
        return response.data.data;
    };
}