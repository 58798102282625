import { createSlice } from "@reduxjs/toolkit";

export const viewpointSlice = createSlice({
    name: "viewpoint",
    initialState: {
        loading: false,
        total: 0,
        list: [],
        categories: [],
        varieties: [{
            label: "全部",
            value: "",
        }],
        classifications: [{
            label: "全部",
            value: "",
        }],
        filter: {
            interval: "",
            variety: [],
            classification: [],
            dateRange: [],
            sort: "created",
        },
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setTotal: (state, action) => {
            state.total = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        setCategories: (state, action) => {
            const categories = action.payload;
            state.categories = categories;
            const varieties = [{
                label: "全部",
                value: "",
            }].concat(
                categories.map(item => ({
                    label: item.name,
                    value: item.id,
                })),
            );
            state.varieties = varieties;
            const variety = [];
            state.filter.variety = variety;
            const classifications = [{
                label: "全部",
                value: "",
            }].concat(
                categories
                    ?.find(item => item.id === variety[0])
                    ?.collections
                    ?.map(item => ({
                        label: item.name,
                        value: item.id,
                    })) ?? []
            );
            state.classifications = classifications;
            state.filter.classification = [];
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setSingleFilter: (state, action) => {
            const { key, value } = action.payload;
            if (key === "variety") {
                const classifications = [{
                    label: "全部",
                    value: "",
                }].concat(
                    state.categories
                        ?.find(item => item.id === value[0])
                        ?.collections
                        ?.map(item => ({
                            label: item.name,
                            value: item.id,
                        })) ?? []
                );
                state.classifications = classifications;
                state.filter.classification = [];
            }
            state.filter[key] = value;
        },
    },
});

export const { setLoading, setTotal, setList, setCategories, setFilter, setSingleFilter } = viewpointSlice.actions;

export default viewpointSlice.reducer;