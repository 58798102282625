export const PERMISSION_TEST_FUNCTIONS = {
    unauthorized: (auth, user, exact) => {
        return exact ? auth.loggedIn === false : true;
    },
    user: (auth, user, exact) => {
        return exact ? auth.loggedIn === true && user.is_superuser === false : auth.loggedIn === true;
    },
    admin: (auth, user, exact) => {
        // exact is not used here
        // because admin is the highest permission
        return auth?.loggedIn && user?.is_superuser;
    },
};